import React from 'react';
import loadable from '@loadable/component';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, ThemeProvider, Zoom } from '@mui/material';
import { theme } from './src/themes/standard-theme';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { firebaseApp } = loadable(async () => await import('./src/firebase'));

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      TransitionComponent={Zoom}
    >
      {element}
    </SnackbarProvider>
  </ThemeProvider>
);
