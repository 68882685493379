module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Swipe Your Meal!","short_name":"SWEAL","start_url":"/","background_color":"#4caf50","theme_color":"#4caf50","display":"minimal-ui","icon":"src/images/sweal-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"72482642db6874886d1c96c3a7d2d393"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
