/***************************************
 * Theme Design for Dream-bit-website. *
 ***************************************/

import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#80e27e',
      main: '#4caf50',
      dark: '#087f23',
      contrastText: '#fafafa',
    },
    secondary: {
      light: '#ffc947',
      main: '#ff9800',
      dark: '#c66900',
      contrastText: '#fafafa',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});
